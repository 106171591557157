<template>
  <div class="page-content has-padding">
    <ui-page-hero :title="'Active carts'" />

    <section class="section is-small">
      <div class="container">
        <cart-list></cart-list>
      </div>
    </section>
  </div>
</template>

<script>
const CartList = () => import('@/components/Carts/CartList')

export default {
  components: {
    'cart-list': CartList,
  },

  data() {
    return {}
  },
}
</script>
